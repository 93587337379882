import $ from 'jquery';
import 'slick-carousel';

import { debounce } from './components';

$(document).ready(() => {

	setTimeout(() => {
		$('.pageLoading').addClass('pageLoading--hidden');

		$('.mainSlider').addClass('mainSlider--started');
		$('.logoGroup').addClass('logoGroup--visible');
		$('.menuToggle').addClass('menuToggle--visible');
		$('.logo').addClass('logo--visible');
		$('.works').addClass('works--visible');
		$('.clients').addClass('clients--visible');
		$('.people').addClass('people--visible');

	}, 1200);

	setInterval(() => {
		$('.menuToggle').toggleClass('menuToggle--menu');
	}, 6000);

	$('.logoGroup, .group__close').on('click', e => {
		e.preventDefault();

		$('.logoGroup').toggleClass('logoGroup--active');
		$('.group').toggleClass('group--visible');

		$('.menu').removeClass('menu--visible');
		$('.headerNav').removeClass('headerNav--menuVisible');

		window.scrollTo(0, 0);
	});

	$('.mainSlider__list').slick({
		dots: true,
		appendDots: '.mainSlider__dots',
		slidesToShow: 1,
		autoplay: true,
		autoplaySpeed: 5000,
		speed: 1200,
		arrows: false,
		/*prevArrow: '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg></button>',
		nextArrow: '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg></button>'*/
	}).on( 'beforeChange', function( event, slick, currentSlide, nextSlide ) {
		if ( nextSlide == 0 ) {
			var cls = 'slick-current slick-active';
			setTimeout(function() {
				$( '[data-slick-index="' + slick.$slides.length + '"]' ).addClass( cls ).siblings().removeClass( cls );
				for (var i = slick.options.slidesToShow - 1; i >= 0; i--) {
					$( '[data-slick-index="' + i + '"]' ).addClass( cls );
				}
			}, 10);
		}
	});

	$('.people__slider').slick({
		//slidesToShow: 3,
		slidesToShow: 4,
		slidesToScroll: 1,
		//asNavFor: '.people__contentSlider',
		arrows: true,
		dots: false,
		//focusOnSelect: true,

		prevArrow: '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg></button>',
		nextArrow: '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg></button>',

		// remove when content arrives
		autoplay: true,
  		autoplaySpeed: 2000,

		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					centerMode: true,
					centerPadding: '20px',
				}
			}
		]
	});

	/*$('.people__contentSlider').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		fade: true,
		asNavFor: '.people__slider',
		autoplay: true,
  		autoplaySpeed: 2000,
	});*/

	$('.office__slider').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		prevArrow: '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg></button>',
		nextArrow: '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg></button>'
	});

	$('.caseGallery__slider').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		centerMode: true,
		centerPadding: '420px',
		autoplay: true,
		autoplaySpeed: 5000,
		speed: 1000,
		prevArrow: '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg></button>',
		nextArrow: '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg></button>',
		responsive: [
			{
				breakpoint: 1100,
				settings: {
					centerPadding: '30px',
				}
			},
			{
				breakpoint: 1500,
				settings: {
					centerPadding: '240px',
				}
			}
		]
	});

	$('.works--more .container').slick({
		slidesToShow: 2,
		slidesToScroll: 2,
		arrows: true,
		prevArrow: '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg></button>',
		nextArrow: '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg></button>',
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			}
		]
	});

	$('.menuToggle').on('click', e => {
		e.preventDefault();

		$('.menu').toggleClass('menu--visible');
		$('.headerNav').toggleClass('headerNav--menuVisible');

		var menuHeight = $('.menu__list').height();

		$('.menu__background').css('height', menuHeight + 100);

		$('.logoGroup').removeClass('logoGroup--active');
		$('.group').removeClass('group--visible');
	});

	$('.toggleArea').on('click', e => {
		e.preventDefault();

		let area = $(e.target).data('area') || $(e.currentTarget).data('area');

		if (area) {
			if ($('#' + area).hasClass('areas__content--active')) {
				$('#' + area).removeClass('areas__content--active');
			} else {
				$('.areas__content--active').removeClass('areas__content--active');
				$('#' + area).addClass('areas__content--active');
			}
		}
	});








	//videos
	function loadVideo (el, path) {
        var req = new XMLHttpRequest();
        req.open('GET', path, true);
        req.responseType = 'blob';

        //var video = document.getElementsByClassName(el);
		var video = $('.' + el);

        req.onload = function() {
           // Onload is triggered even on 404
           // so we need to check the status code
           if (this.status === 200) {
              var videoBlob = this.response;
              var vid = URL.createObjectURL(videoBlob); // IE10+
              // Video is now downloaded
              // and we can set it as source on the video element
              
			  //video.src = vid;
			  $(video).attr('src', vid);

              //$('#' + el).css('display', 'block');
			  $('.' + el).addClass('videoLoaded');

              //videos_loaded++;

              //first_video_loaded = true;

			  /*if ($('body').hasClass('page-home')) {
			  	setVideoContainerHeight();
			  }*/
           }
        }
        req.onerror = function() {
           // Error
        }
        req.send();
    }

    function videoSize () {
        var video_x = 1920;
        var video_y = 1080;
        var window_x = $(window).width();
        var window_y = $(window).height();

        if (window_x > window_y) {
            var nx = $(window).width();
            var ny = 0;
            ny = (video_y / video_x) * nx;
            if(window_y > ny){
                var nx = 0;
                var ny = $(window).height();
                nx = (video_x / video_y) * ny;
            }

			$('.videoItem').css('margin-left', 0);
        } else {
            var nx = 0;
            var ny = $(window).height();
            nx = (video_x / video_y) * ny;

			$('.videoItem').css('margin-left', - (nx / 4));
        }

        $('.videoItem').css('width', nx);
        $('.videoItem').css('height', ny);

		$('.mainSlider__item').css('height', ny);
    }

	/*function setVideoContainerHeight () {
		var windowWidth = $(window).width();

		var videoLoaded = $('.videoItem.videoLoaded');
		var videoHeight = $(videoLoaded).height();
		var mainSliderHeight = $('.mainSlider__item').height();

		if (windowWidth > 1700) {
			var headerHeight = $('.header').outerHeight();
			var finalSliderHeight = videoHeight - headerHeight;
		} else {
			var finalSliderHeight = videoHeight;
		}

		if (finalSliderHeight !== mainSliderHeight) {
			//$('.mainSlider__item').css('height', finalSliderHeight);
		}
	}*/

	var mobileDeviceWidth = 1024;

	if ($('body').hasClass('home')) {

		function resize () {
			var windowWidth = $(window).width();
			if (windowWidth > mobileDeviceWidth) {
				loadVideo('video-1', 'public/video/coala.mp4');
				loadVideo('video-2', 'public/video/jaguariuna.mp4');
				loadVideo('video-3', 'public/video/cbb.mp4');

				//$('.videoContainer').css('left', '50%');
				//$('.videos .video video').css('display', 'block');
			} else {
				//$('.videoContainer').css('left', '0');
				//$('.videoContainer').css('width', '100%');
				//$('.videoContainer').css('height', '100%');
				//$('.videoContainer').css('margin-left', '0');
				//$('.videos .video video').css('display', 'none');
			}

			videoSize();
		}
	
		$(window).on('resize', resize);
		resize();

	}

	if ($('body').hasClass('case')) {
		function resize () {
			var windowWidth = $(window).width();
			if (windowWidth > mobileDeviceWidth) {
				var videoSrc = $('#video-1').data('src');
				loadVideo('video-1', videoSrc);
			}

			videoSize();
		}

		$(window).on('resize', resize);
		resize();
	}

	function fixHeader () {
		var top = $(window).scrollTop();
		if (top > 0) {
			$('.headerNav').addClass('headerNav--fixed');

			$('.group').addClass('group--noTransition');
			setTimeout(function () {
				$('.group').removeClass('group--noTransition');
			}, 300);

			$('.logoGroup').removeClass('logoGroup--active');
			$('.group').removeClass('group--visible');

		} else {
			$('.headerNav').removeClass('headerNav--fixed');
		}
	}

	$(window).on('scroll', fixHeader);
	fixHeader();

	/*$('.js-open-group').on('click', e => {
		e.preventDefault();

		var group = $('.group');

		if ($(group).hasClass('group--visible')) {
			$(group).removeClass('group--visible');
		} else {
			$(group).addClass('group--visible');
		}

		window.scrollTo(0, 0);
	});*/

	function isScrolledIntoView (elem) {
		var docViewTop = $(window).scrollTop();
		var docViewBottom = docViewTop + $(window).height();

		var elemTop = $(elem).offset().top;
		var elemBottom = elemTop + $(elem).height();

		//return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
		return elemTop <= docViewBottom && elemTop >= docViewTop;
	}

	function changeTheme () {
		if ($('.js-change-theme').length > 0) {
			let intoView = isScrolledIntoView('.js-change-theme');
			let theme = $('.js-change-theme').data('theme');
			
			if (intoView) {
				if (!$('body').hasClass(theme)) {
					$('body').addClass(theme);
				}
			} else {
				if ($('body').hasClass(theme)) {
					$('body').removeClass(theme);
				}
			}
		}

		if ($('.js-change-theme-2').length > 0) {
			let intoView2 = isScrolledIntoView('.js-change-theme-2');
			let theme2 = $('.js-change-theme-2').data('theme');

			if (intoView2) {
				if (!$('body').hasClass(theme2)) {
					$('body').addClass(theme2);
				}
			} else {
				if ($('body').hasClass(theme2)) {
					$('body').removeClass(theme2);
				}
			}
		}
	}

	let debouncedScroll = debounce(() => changeTheme(), 300);
	$(window).on('scroll', debouncedScroll);
	changeTheme();
	
});